
      @import 'styles-scss/abstracts/mixins';
    
.section {
  position: relative;
}

.wrapper {
  position: relative;

  @include break-and-break-reverse(576, 1239) {
    padding-block: calc(var(--space-md) * 2);
    padding-inline: var(--space);
    background-color: rgb(from var(--neutral-0) r g b / 0.02);
    box-shadow: inset 0 0 15px 0 rgb(from var(--neutral-25) r g b / 0.1);

    @include gradient-border();
    @include gradient-border-angle(145deg);
    @include gradient-border-colors(
      rgb(from var(--primary-100) r g b / 0),
      var(--neutral-55),
      rgb(from var(--primary-100) r g b / 0)
    );
  }

  @include break-reverse(1240) {
    padding: calc(var(--space-lg) * 2) var(--space-xxl);

    &::before {
      content: '';
      position: absolute;
      background-image: url('./best-support-gradient-border.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: top center;
      inset: -70px;
    }
  }

  &__insetMedium {
    @include break-reverse(1240) {
      &::before {
        inset: -84px;
      }
    }
  }

  &__insetLarge {
    @include break-reverse(1240) {
      &::before {
        inset: -112px;
      }
    }
  }
}

.badges {
  margin-bottom: calc(var(--space-md) * 2);

  @include break-reverse(768) {
    margin-bottom: var(--space-xxl);
  }
}
